import { React, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  ModalFooter,
  Button,
  Label,
} from "reactstrap";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { process } from "@progress/kendo-data-query";
import { ColumnMenu } from "../components/kendoComponents/columnMenu/ColumnMenu";
import { GridPager } from "../components/kendoComponents/gridPager/GridPager";
import { ColumnProps } from "../components/kendoComponents/columnProps/ColumnProps";
import { useNavigate } from "react-router-dom";
import LoaderSpin from "../components/loaderSpin/LoaderSpin";
import PropTypes from "prop-types";
import moment from "moment";


import {
  DropDownButton,
  DropDownButtonItem,
} from "@progress/kendo-react-buttons";

import "../assets/scss/kendo-theme-custom.scss";
import {
  fetchLastProcessList,
  setFilterProcessType,
  getSelectedProcess,
  fetchNewRunParameters,
  newRun,
} from "../store/app/ProcessRuns/ProcessRunsSlice";

import DateCell from "../components/kendoComponents/dateCell/DateCell";
import { setFilterIntervallo } from "../store/app/DataQualityStatus/DataQualitySlice";

const CustomTooltip = ({ dataItem }) => {
  return (
    <ul>
      {dataItem.tooltip.map((item) => (
        <li key={item.id}>
          <strong>{item.id}: </strong>
          {(() => {
            if (
              item.id === "Documentazione funzionale" ||
              item.id === "Documentazione tecnica"
            ) {
              return item.description ? (
                <a
                  href={item.description}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {item.description}
                </a>
              ) : (
                "N/A"
              );
            } else {
              return item.description || "N/A";
            }
          })()}
        </li>
      ))}
    </ul>
  );
};

const CustomRow = (props) => {
  const { dataItem } = props;
  const [tooltipVisible, setTooltipVisible] = useState(false);

  const showTooltip = (event) => {
    setTooltipVisible(true);
  };

  const hideTooltip = () => {
    setTooltipVisible(false);
  };

  return (
    <>
      <tr
        className="grid-row-custom"
        {...props}
        onMouseEnter={showTooltip}
        onMouseLeave={hideTooltip}
      >
        {props.children}
      </tr>
      {tooltipVisible && (
        <div
          className="custom-tooltip"
          onMouseEnter={() => setTooltipVisible(true)}
          onMouseLeave={hideTooltip}
          onFocus={() => setTooltipVisible(true)}
          onBlur={hideTooltip}
          onKeyDown={(e) => {
            if (e.key === "Escape") {
              hideTooltip();
            }
          }}
        >
          <CustomTooltip dataItem={dataItem} />
        </div>
      )}
    </>
  );
};

const NewRunModal = (props) => {
  const { modal, newRunParameters, toggleModal, handleOk } = props;

  console.log("NewRunModal", newRunParameters);

  const [parameters, setParameters] = useState([]);
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});

  useEffect(() => {
    setParameters(newRunParameters);
    const initialValues = newRunParameters.reduce((acc, param) => {
      acc[param.name] = param.type === "boolean" ? false : "";
      return acc;
    }, {});
    setValues(initialValues);
  }, [newRunParameters]);

  const handleInputChange = (name, value, originalValue) => {
    console.log("handleInputChange", name, value);

    setValues({ ...values, [name]: value, [name + "orinalValue"]: originalValue });
  };

  const handleSubmit = () => {
    const newErrors = {};
    parameters.forEach((param) => {
      if (!param.is_optional && !values[param.name]) {
        newErrors[param.name] = "This field is required";
      }
    });

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    const payload = parameters.map((param) => ({
      name: param.name,
      value: values[param.name],
      type: param.type,
    }));

    handleOk(payload);
  };

  const getInputType = (index, param) => {
    switch (param.type) {
      case "date":
        return (
          <Input
            type="datetime-local"
            id={`param-${index}-${param.name}`}
            placeholder="-"
            value={values[`${param.name}_orinalValue`]}
            onChange={(event) =>
              handleInputChange(param.name, moment(event.target.value).format('YYYYMMDD'), event.target.value)
            }
          />
        );
      case "boolean":
        return (
          <Input
            type="checkbox"
            id={`param-${index}-${param.name}`}
            checked={values[`${param.name}_orinalValue`]}
            onChange={(e) => handleInputChange(param.name, e.target.checked, e.target.checked)}
          />
        );
      default:
        return (
          <Input
            type="text"
            id={`param-${index}-${param.name}`}
            value={values[`${param.name}_orinalValue`]}
            onChange={(e) => handleInputChange(param.name, e.target.value, e.target.value)}
          />
        );
    }
  };

  return (
    <Modal isOpen={modal} size="xs mt-6">
      <ModalHeader className="" toggle={toggleModal}>
        <h5 className="pt-2 fw-bold">Nuova Run</h5>
      </ModalHeader>
      <ModalBody>
        <Form>
          <div className="form-body">
            <Row>
              {parameters.length === 0 ? (
                <Col>
                  <p className="text-warning">
                    Non sono presenti parameteri editabili per la riesecuzione
                    di questa run, vuoi procedere comunque?
                  </p>
                </Col>
              ) : (
                parameters.map((param, index) => (
                  <Col key={index}>
                    <FormGroup>
                      <Label for={`param-${index}-${param.name}`}       
                      title={param.description && param.description.trim() ? param.description : undefined} 
                      >
                        {param.name}{" "}
                        {!param.is_optional && (
                          <span className="text-danger">*</span>
                        )}
                      </Label>
                      {getInputType(index, param)}
                      {errors[param.name] && (
                        <div className="text-danger">{errors[param.name]}</div>
                      )}
                    </FormGroup>
                  </Col>
                ))
              )}
            </Row>
          </div>
        </Form>
      </ModalBody>
      <ModalFooter className="border-primary justify-content-between">
        <Button
          color="primary"
          outline
          className="text-uppercase"
          onClick={toggleModal}
        >
          Annulla
        </Button>
        <Button
          color="primary"
          className="text-uppercase"
          onClick={() => handleSubmit()}
        >
          Conferma
        </Button>
      </ModalFooter>
    </Modal>
  );
};

CustomTooltip.propTypes = {
  dataItem: PropTypes.object.isRequired,
};

CustomRow.propTypes = {
  dataItem: PropTypes.object.isRequired,
};

NewRunModal.propTypes = {
  modal: PropTypes.bool.isRequired,
  newRunParameters: PropTypes.object.isRequired,
  toggleModal: PropTypes.func.isRequired,
  handleOk: PropTypes.func.isRequired,
};

const ProcessSelection = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const refreshCount = useSelector(
    (state) => state.dataQualityReducer.refreshCount
  );
  const searchParams = new URLSearchParams(location.search);
  const lastProcessList = useSelector(
    (state) => state.processRunsReducer.lastProcessList
  );
  const newRunParameters = useSelector(
    (state) => state.processRunsReducer.newRunParameters
  );

  const isSpinnerVisible = useSelector(
    (state) => state.processRunsReducer.loading
  );

  const [dataState, setDataState] = useState({
    sort: [
      {
        field: "code",
        dir: "asc",
      },
    ],
    skip: 0,
    take: 20,
  });

  const [dataResult, setDataResult] = useState(
    process(lastProcessList, dataState)
  );

  const [modal, setModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});

  useEffect(() => {
    dispatch(fetchLastProcessList());
  }, [refreshCount, navigate]);

  useEffect(() => {
    setDataResult(process(lastProcessList, dataState));
  }, [lastProcessList]);

  const goToLatestRuns = (processId, processTypeName) => {
    dispatch(setFilterIntervallo(744));
    dispatch(setFilterProcessType([processTypeName]));
    dispatch(
      getSelectedProcess({
        processId: processId,
        processTypeName: processTypeName,
      })
    );

    searchParams.set("interval", JSON.stringify(744));
    searchParams.set("process", JSON.stringify([processTypeName]));

    navigate(
      "/process-selection/latest-runs/" +
        processId +
        "?" +
        searchParams.toString()
    );
  };

  const openModalNewRun = (processId, revisionNum) => {
    dispatch(fetchNewRunParameters(processId, revisionNum));
    setSelectedRow({ processId: processId, revisionNum: revisionNum });
    setModal(true);
  };

  const toggleModal = () => {
    setModal(!modal);
  };

  const prefectCall = (parameters) => {
    toggleModal();
    dispatch(newRun(selectedRow, parameters));    
  };

  return (
    <>
      <Container fluid className="py-2 px-4  position-relative">
        <Row>
          <Col>
            <div className="grid_task_container border">
              <Grid
                style={{ height: "100%" }}
                pageable={true}
                sortable={true}
                total={dataResult?.length}
                data={dataResult}
                {...dataState}
                pager={(props) => GridPager(props, lastProcessList)}
                onDataStateChange={(e) => {
                  setDataState(e.dataState);
                  setDataResult(process(lastProcessList, e.dataState));
                }}
                rowRender={(row, rowProps) => <CustomRow {...rowProps} />}
              >
                <Column
                  {...ColumnProps("name", dataState)}
                  title="Nome"
                  columnMenu={ColumnMenu}
                />
                <Column
                  {...ColumnProps("processTypeName", dataState)}
                  title="Tipo Processo"
                  columnMenu={ColumnMenu}
                />
                <Column
                  {...ColumnProps("current_revision_number", dataState)}
                  title="Revisione Corrente"
                  columnMenu={ColumnMenu}
                />
                <Column
                  {...ColumnProps("current_revision_date", dataState)}
                  title="Data rilascio"
                  columnMenu={ColumnMenu}
                  cell={(props) => DateCell(props, true)}
                />
                <Column
                  {...ColumnProps("output_type_name", dataState)}
                  title="Data Product"
                  columnMenu={ColumnMenu}
                />
                <Column
                  title=" "
                  width="60"
                  cell={(props) => (
                    <td>
                      <div className="text-end"></div>
                      <a
                        className="fw-bold text-decoration-underline"
                        href="#"
                      ></a>
                      <DropDownButton
                        buttonClass="border-0"
                        iconClass="k-icon k-i-menu"
                        popupClass="p-3"
                        rounded="null"
                        fillMode="null"
                        ariaLabel="Azioni"
                        ariaRole="button"
                        onItemClick={(event) => {
                          if (
                            event.item.text ==
                            "/process-selection/latest-runs/" +
                              props.dataItem.process_id
                          ) {
                            goToLatestRuns(
                              props.dataItem.process_id,
                              props.dataItem.process_type_name
                            );
                          } else {
                            openModalNewRun(
                              props.dataItem.process_id,
                              props.dataItem.current_revision_number
                            );
                          }
                        }}
                      >
                        <DropDownButtonItem
                          props={props}
                          text={
                            "/process-selection/latest-runs/" +
                            props.dataItem.process_id
                          }
                          render={() => <li>Latest runs</li>}
                        />
                        <DropDownButtonItem
                          props={props}
                          text={
                            "/process-selection/new-run/" +
                            props.dataItem.process_id
                          }
                          render={() => <li>New Run</li>}
                        />
                      </DropDownButton>
                    </td>
                  )}
                />
              </Grid>
            </div>
          </Col>
        </Row>
        <LoaderSpin isVisible={isSpinnerVisible} />
      </Container>
      <NewRunModal
          modal={modal}
          newRunParameters={newRunParameters}
          toggleModal={toggleModal}
          handleOk={prefectCall}
          
        > </NewRunModal>
      
    </>
  );
};

export default ProcessSelection;
