import { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  setFilterDataProducts,
  setFilterExtendedStatsDomains,
  setFilterIntervallo,
  setFilterDataDa,
  setFilterDataA,
} from "../../store/app/DataQualityStatus/DataQualitySlice";
import moment from "moment";


// Function to map data into a tree-like structure for rendering
const mapDataToTree = (levels, primaryKey) => {
  const nodes = {};
  levels.forEach((level) => {
    level.points.forEach((point) => {
      nodes[point[primaryKey]] = {
        ...point,
        level: level.level,
        children: [],
      };
    });
  });

  // Assign parent-child relationships
  Object.values(nodes).forEach((node) => {
    node.parentIds.forEach((parentId) => {
      if (nodes[parentId]) {
        nodes[parentId].children.push(node);
      }
    });
  });

  // Find root nodes (those with no parent)
  return Object.values(nodes).filter((node) => node.parentIds.length === 0);
};

const generateNode = (item, suffix, primaryKey) => {
  const node = {};
  const originID = item["origin_" + primaryKey];

  // Format reference_date to YYYY-MM-DD
  const formatDate = (date) => new Date(date).toISOString().split("T")[0];

  // Add all non-origin values to the node
  Object.keys(item).forEach((key) => {
    if (!key.startsWith("origin_") && !key.endsWith("Origin")) {
      node[key] = item[key];
    }
  });

  // Update non-origin values with origin values if suffix is "origin_"
  if (suffix === "origin_") {
    Object.keys(item).forEach((key) => {
      if (key.startsWith("origin_") || key.endsWith("Origin")) {
        const nonOriginKey = key.replace("origin_", "").replace("Origin", "");
        if (node.hasOwnProperty(nonOriginKey)) {
          node[nonOriginKey] = item[key];
        }
      }
    });
  }

  if (primaryKey == "version_id") {
    node["reference_date"] = formatDate(node.reference_date);
    node["label"] = `${node[primaryKey]} - ${node["product_name"]}`;
  } else if (primaryKey == "type_id") {
    node["label"] = node.type_name;
  }

  if (originID && suffix != "origin_") {
    node["parentIds"] = [originID.toString()];
  } else {
    node["parentIds"] = [];
  }

  return node;
};
const normalizeData = (payload, params) => {
  const levelsMap = new Map();
  const nodesMap = new Map();

  // Find the minimum origin_generation to normalize levels
  const minOriginGeneration = Math.min(
    ...payload.map((item) => item.origin_generation)
  );

  payload.forEach((item) => {
    // Normalize levels so that the minimum origin_generation becomes level 0
    const level = item.generation - minOriginGeneration;
    const originLevel = item.origin_generation - minOriginGeneration;
    const keyFrom = "origin_" + params.primaryKey;
    const keyTo = params.primaryKey;

    if (!nodesMap.has(item[keyFrom])) {
      const originNode = generateNode(item, "origin_", keyTo);

      if (!levelsMap.has(originLevel)) {
        levelsMap.set(originLevel, []);
      }
      levelsMap.get(originLevel).push(originNode);
      nodesMap.set(item[keyFrom], originNode);
    }

    // Add destination node (child)
    if (!nodesMap.has(item[keyTo])) {
      const destinationNode = generateNode(item, "", keyTo);

      if (!levelsMap.has(level)) {
        levelsMap.set(level, []);
      }
      levelsMap.get(level).push(destinationNode);
      nodesMap.set(item[keyTo], destinationNode);
    } else {
      // If the node already exists, update its parentIds to include the new parent
      const existingNode = nodesMap.get(item[keyTo]);

      if (
        item[keyFrom] &&
        !existingNode.parentIds.includes(item[keyFrom].toString())
      )
        existingNode.parentIds.push(item[keyFrom].toString());
    }
  });

  const levels = Array.from(levelsMap.entries())
    .map(([level, points]) => ({
      level,
      count: points.length,
      points,
    }))
    .sort((a, b) => a.level - b.level);

  const totalLevels = levels.length;
  const totalPoints = nodesMap.size;

  return {
    summary: {
      totalLevels,
      totalPoints,
    },
    levels,
  };
};




// Helper function to calculate node position (X, Y coordinates)
const calculateNodePosition = (
  node,
  levelWidth,
  levelSpacing,
  nodeSpacing,
  maxPointsPerLevel
) => {
  const x = node.level * levelSpacing + levelWidth * node.level + 100; // Horizontal spacing between levels
  const maxLevelHeight = maxPointsPerLevel * nodeSpacing; // Maximum height for any level
  const yOffset = (maxLevelHeight - node.levelCount * nodeSpacing) / 2 + 100; // Center nodes vertically
  const y = node.index * nodeSpacing + yOffset; // Vertical spacing between nodes within a level

  return { x, y };
};

const LineageDrawer = ({ data, params = {} }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);

  const [menuVisible, setMenuVisible] = useState(false);
  const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });
  const [selectedNode, setSelectedNode] = useState({});

  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
  const [tooltipNode, setTooltipNode] = useState("");

  const [lineTooltipVisible, setLineTooltipVisible] = useState(false);
  const [lineTooltipPosition, setLineTooltipPosition] = useState({
    x: 0,
    y: 0,
  });
  const [lineTooltip, setLineTooltip] = useState("");

  const [highlightedLine, setHighlightedLine] = useState("");
  const [highlightedNode, setHighlightedNode] = useState("");

  const [zoomLevel, setZoomLevel] = useState(1);
  const [transformOrigin, setTransformOrigin] = useState({ x: 0, y: 0 });


  const handleZoomIn = (event) => {
    const svgRect = event.target.getBoundingClientRect();
    const mouseX = event.clientX - svgRect.left;
    const mouseY = event.clientY - svgRect.top;
    setTransformOrigin({ x: mouseX, y: mouseY });
    setZoomLevel((prevZoomLevel) => Math.min(prevZoomLevel * 1.2, 5));
  };
  const handleZoomOut = (event) => {
    const svgRect = event.target.getBoundingClientRect();
    const mouseX = event.clientX - svgRect.left;
    const mouseY = event.clientY - svgRect.top;
    setTransformOrigin({ x: mouseX, y: mouseY });
    setZoomLevel((prevZoomLevel) => Math.max(prevZoomLevel / 1.2, 0.2));
  };

  const handleResetZoom = () => {
    setZoomLevel(1);
    setTransformOrigin({ x: 0, y: 0 });
  };



 // Add event listeners for keyboard and mouse events
 useEffect(() => {
  const handleKeyDown = (event) => {
    if (event.altKey && event.key === "+") {
      handleZoomIn(event);
    } else if (event.altKey && event.key === "-") {
      handleZoomOut(event);
    }
  };

  const handleWheel = (event) => {
    if (event.altKey) {
      if (event.deltaY < 0) {
        handleZoomIn(event);
      } else {
        handleZoomOut(event);
      }
    }
  };

  window.addEventListener("keydown", handleKeyDown);
  window.addEventListener("wheel", handleWheel);

  return () => {
    window.removeEventListener("keydown", handleKeyDown);
    window.removeEventListener("wheel", handleWheel);
  };
}, []);

  

  // Add a check to handle empty or non-existent data
  if (!data || data.length === 0) {
    return (
      <div className="lineageNoData">
        <h1>No data available</h1>
      </div>
    ); // Highlighted line
  }

  const normalizedData = normalizeData(data, params);
  const treeData = mapDataToTree(normalizedData.levels, params.primaryKey);
  

  

  const defaultButtons = [
    {
      label: "Single Version DQ",
      action: () => {
        console.log("Single Version DQ");
        navigate(
          "/versioni/detail/single-version/" +
            selectedNode.version_id +
            "?" +
            searchParams
        );
      },
    },
    {
      label: "Log",
      action: () => {
        console.log("Log");
        navigate(
          "/process-runs/detail/application-log/" +
            selectedNode.version_id +
            "?" +
            searchParams
        );
      },
    },
    {
      label: "Quick Search",
      action: () => {
        dispatch(setFilterDataProducts(selectedNode.data_product_id || ""));
        dispatch(setFilterIntervallo(-2));

        dispatch(setFilterDataDa(moment(selectedNode.reference_date).format("YYYY-MM-DD HH:mm:ss") || ""));
        dispatch(setFilterDataA(moment(selectedNode.reference_date).add(24, "hours").format("YYYY-MM-DD HH:mm:ss") || "" ));

        dispatch(setFilterExtendedStatsDomains(selectedNode.status_id));

        searchParams.set("extendedStatsDomains",JSON.stringify([selectedNode.status_id]));
        searchParams.set("fromDate",    moment(selectedNode.reference_date).format("YYYY-MM-DD HH:mm:ss"));
        searchParams.set("toDate",    moment(selectedNode.reference_date).add(24, "hours").format("YYYY-MM-DD HH:mm:ss"));
        searchParams.set("interval", -2);

        navigate("/versioni" + "?" + searchParams);
      },
    },
    {
      label: "Version Lineage",
      action: () => {
        console.log("Version Lineage");
        navigate(
          "/versioni/version-lineage/" +
            selectedNode.version_id +
            "?" +
            searchParams
        );
      },
    },
  ];

  // Calculate SVG dimensions
  const levelWidth = 100;
  const levelSpacing = 150;
  const nodeSpacing = 100;
  const maxPointsPerLevel = Math.max(
    ...normalizedData.levels.map((level) => level.count)
  );

  const svgWidth =
    normalizedData.summary.totalLevels * (levelWidth + levelSpacing);
  const svgHeight = maxPointsPerLevel * nodeSpacing + 50;

  const renderedNodes = new Set();
  const nodePositions = {};

  // Calculate positions for all nodes first
  const calculatePositions = (
    nodes,
    levelWidth,
    levelSpacing,
    nodeSpacing,
    levelIndices = {},
    visitedNodes = new Set()
  ) => {
    nodes.forEach((node) => {
      if (visitedNodes.has(node[params.primaryKey])) return; // Skip already visited nodes
      visitedNodes.add(node[params.primaryKey]);

      if (!levelIndices[node.level]) {
        levelIndices[node.level] = 0;
      }

      node.index = levelIndices[node.level]++;
      node.levelCount = normalizedData.levels[node.level].count;

      const position = calculateNodePosition(
        node,
        levelWidth,
        levelSpacing,
        nodeSpacing,
        maxPointsPerLevel
      );

      if (!isNaN(position.x) && !isNaN(position.y)) {
        nodePositions[node[params.primaryKey]] = position;
      }

      calculatePositions(
        node.children,
        levelWidth,
        levelSpacing,
        nodeSpacing,
        levelIndices,
        visitedNodes
      );
    });
  };

  calculatePositions(treeData, levelWidth, levelSpacing, nodeSpacing);

  const openMenu = (node, event) => {
    
    setMenuVisible(false); // Hide the menu first to reset the animation

    setTimeout(() => {
      setTooltipVisible(false); // Hide the tooltip when the menu is opened
      unhighlightNode(); // Unhighlight the node when the menu is opened
      setMenuVisible(true);
      setMenuPosition({
        x: event.clientX + window.scrollX + 10,
        y: event.clientY + window.scrollY + 10,
      }); // Adjust position to be under the node
      setSelectedNode(node);
    }, 100); // Small delay to allow the animation to reset
  };

  // Handle node click event
  const handleNodeClick = (event, node) => {
    event.stopPropagation(); // Prevent event from bubbling up
    openMenu(node, event);
  };

  const closeMenu = () => {
    setMenuVisible(false);
    setSelectedNode({});
  };

  const showTooltip = (node, event) => {
    if (
      menuVisible &&
      selectedNode[params.primaryKey] == node[params.primaryKey]
    )
      return; // Do not show tooltip if menu is visible

    setTooltipVisible(true);
    setTooltipPosition({
      x: event.clientX + window.scrollX + 10,
      y: event.clientY + window.scrollY + 10,
    });
    setTooltipNode(node);
    highlightNode(node[params.primaryKey]);
  };

  const hideTooltip = () => {
    setTooltipVisible(false);
    unhighlightNode();
  };

  const highlightLine = (event, lineId, child) => {
    setHighlightedLine(lineId);
    setLineTooltipVisible(true);
    setLineTooltipPosition({
      x: event.clientX + window.scrollX,
      y: event.clientY + window.scrollY,
    });
    setLineTooltip(child);
  };

  const unhighlightLine = () => {
    setHighlightedLine("");
    setLineTooltipVisible(false);
  };

  const highlightNode = (nodeId) => {
    setHighlightedNode(nodeId);
  };

  const unhighlightNode = () => {
    setHighlightedNode("");
  };

  const renderLine = (node, child, childIndex) => {
    const childPosition = nodePositions[child[params.primaryKey]];
    if (childPosition) {
      const dx = childPosition.x - nodePositions[node[params.primaryKey]].x;
      const dy = childPosition.y - nodePositions[node[params.primaryKey]].y;
      const length = Math.sqrt(dx * dx + dy * dy);
      const unitDx = dx / length;
      const unitDy = dy / length;
      const offset = 10;
  
      const newX2 = childPosition.x - unitDx * offset;
      const newY2 = childPosition.y - unitDy * offset;
  
      const lineId = `${node[params.primaryKey]}-${child[params.primaryKey]}-${childIndex}`;
  
      return (
        <Fragment key={lineId}>
          {/* Invisible hit area */}
          <line
            x1={nodePositions[node[params.primaryKey]].x}
            y1={nodePositions[node[params.primaryKey]].y}
            x2={newX2}
            y2={newY2}
            stroke="transparent"
            strokeWidth="25"
            onMouseEnter={(event) => highlightLine(event, lineId, child)}
            onMouseLeave={unhighlightLine}
          />
          {/* Actual line */}
          <line
            x1={nodePositions[node[params.primaryKey]].x}
            y1={nodePositions[node[params.primaryKey]].y}
            x2={newX2}
            y2={newY2}
            stroke={highlightedLine === lineId ? "blue" : "lightgrey"}
            strokeWidth={highlightedLine === lineId ? "3" : "2"}
            markerEnd="url(#arrow)"
          />
        </Fragment>
      );
    }
    return null;
  };

  const renderNodes = (nodes) => {
    const lines = [];
    const circles = [];
  
    const collectRenderData = (nodes) => {
      nodes.forEach((node, index) => {
        const uniqueKey = `${node[params.primaryKey]}-${node.level}-${index}`; // Ensure unique key
        if (!renderedNodes.has(uniqueKey)) {
          renderedNodes.add(uniqueKey); // Mark node as rendered
  
          // Collect lines
          node.children.forEach((child, childIndex) => {
            const line = renderLine(node, child, childIndex);
            if (line) lines.push(line);
          });
  
          // Collect circles
          circles.push(
            <Fragment key={uniqueKey}>
              {/* Node circle */}
              <circle
                cx={nodePositions[node[params.primaryKey]].x}
                cy={nodePositions[node[params.primaryKey]].y}
                r={highlightedNode === node[params.primaryKey] ? 14 : 0}
                fill={node.dotBorderColor ? node.dotBorderColor : node.dotColor}
              ></circle>
              <circle
                cx={nodePositions[node[params.primaryKey]].x}
                cy={nodePositions[node[params.primaryKey]].y}
                r="12"
                fill={node.dotBorderColor ? node.dotBorderColor : node.dotColor}
              ></circle>
              <circle
                cx={nodePositions[node[params.primaryKey]].x}
                cy={nodePositions[node[params.primaryKey]].y}
                r="8"
                fill={node.dotColor}
                onClick={(event) => handleNodeClick(event, node)}
                onMouseEnter={(event) => showTooltip(node, event)}
                onMouseLeave={hideTooltip}
                style={{ cursor: "pointer" }}
              ></circle>
              {/* Node label */}
              <text
                x={nodePositions[node[params.primaryKey]].x}
                y={nodePositions[node[params.primaryKey]].y - 20} // Adjusted position for label
                textAnchor="middle"
                style={{ fontSize: "10px", fontWeight: "bold" }}
                transform={`rotate(30, ${
                  nodePositions[node[params.primaryKey]].x
                }, ${nodePositions[node[params.primaryKey]].y - 20})`}
              >
                {node.label}
              </text>
            </Fragment>
          );
  
          // Recursively collect data for children
          collectRenderData(node.children);
        }
      });
    };
  
    collectRenderData(nodes);
  
    return (
      <Fragment>
        {lines}
        {circles}
      </Fragment>
    );
  };

  // Render the menu
  const renderMenu = () => {
    if (!menuVisible) return null;

    return (
      <div
        className="DAGMenu"
        style={{
          top: menuPosition.y,
          left: menuPosition.x,
          maxWidth: levelSpacing,
        }}
      >
        {(params.menu && params.menu?.defaultButtons) ||
          (!params.menu &&
            defaultButtons.map((button, index) => (
              <button
                key={"default_button_" + index}
                onClick={() => button.action(selectedNode)}
              >
                {button.label}
              </button>
            )))}

        {params.menu?.buttons.map((button, index) => (
          <button
            key={"menu_button_" + index}
            onClick={() => button.action(selectedNode)}
          >
            {button.label}
          </button>
        ))}
      </div>
    );
  };

  // Render the tooltip
  const renderTooltip = () => {
    if (!tooltipVisible) return null;

    return (
      params.nodeTooltipKeys && (
        <div
          className="LineageTooltip"
          style={{
            position: "absolute",
            top: tooltipPosition.y,
            left: tooltipPosition.x,
            backgroundColor: "white",
            border: "1px solid black",
            padding: "5px",
            zIndex: 1000,
            pointerEvents: "none", // Ensure the tooltip does not interfere with mouse events
          }}
        >
          {params.nodeTooltipKeys.map((tooltipKey, index) => (
            <div key={"tooltip_" + index}>
              <strong>{tooltipKey.displayKey}: </strong>{" "}
              {tooltipNode[tooltipKey.key] || "N/A"}
            </div>
          ))}
        </div>
      )
    );
  };

  const renderLineTooltip = () => {
    if (!lineTooltipVisible) return null;

    return (
      params.lineTooltipKeys && (
        <div
          className="LineageTooltip"
          style={{
            position: "absolute",
            top: lineTooltipPosition.y,
            left: lineTooltipPosition.x,
            backgroundColor: "white",
            border: "1px solid black",
            padding: "5px",
            zIndex: 1000,
            pointerEvents: "none", // Ensure the tooltip does not interfere with mouse events
          }}
        >
          {params.lineTooltipKeys.map((tooltipKey, index) => (
            <div key={"tooltip_" + index}>
              <strong>{tooltipKey.displayKey}: </strong>{" "}
              {lineTooltip[tooltipKey.key] || "N/A"}
            </div>
          ))}
        </div>
      )
    );
  };

  return (
    <div onClick={closeMenu}>
      <div className="zoom-buttons">
        <button onClick={handleZoomIn}>Zoom In</button>
        <button onClick={handleZoomOut}>Zoom Out</button>
        <button onClick={handleResetZoom}>Reset Zoom</button>
      </div>
      <svg
        width={svgWidth}
        height={svgHeight}
        style={{
          transform: `scale(${zoomLevel})`,
          transformOrigin: `${transformOrigin.x}px ${transformOrigin.y}px`,
        }}
      >
        <defs>
          <marker
            id="arrow"
            viewBox="0 0 10 10"
            refX="10"
            refY="5"
            orient="auto"
            markerWidth="6"
            markerHeight="6"
          >
            <polygon points="0 0, 10 5, 0 10" fill="lightgrey" />
          </marker>
        </defs>
        {renderNodes(treeData)}
      </svg>
      {renderMenu()}
      {renderTooltip()}
      {renderLineTooltip()}
    </div>
  );
};

LineageDrawer.propTypes = {
  params: PropTypes.shape({
    menu: PropTypes.shape({
      defaultButtons: PropTypes.bool.isRequired,
      buttons: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string.isRequired,
          action: PropTypes.func.isRequired,
        })
      ).isRequired,
    }),
    nodeTooltipKeys: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string.isRequired,
        displayKey: PropTypes.string.isRequired,
      })
    ),
    lineTooltipKeys: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string.isRequired,
        displayKey: PropTypes.string.isRequired,
      })
    ),
    primaryKey: PropTypes.string.isRequired,
  }),
  data: PropTypes.arrayOf(
    PropTypes.shape({
      idx: PropTypes.number.isRequired,
      origin_version_id: PropTypes.number,
      origin_version_name: PropTypes.string,
      origin_version_description: PropTypes.string,
      origin_type_id: PropTypes.string,
      origin_type_name: PropTypes.string,
      origin_type_description: PropTypes.string,
      origin_class_name: PropTypes.string,
      origin_domain_id: PropTypes.string,
      origin_domain_name: PropTypes.string,
      origin_generation: PropTypes.number.isRequired,
      version_id: PropTypes.number,
      version_name: PropTypes.string,
      version_description: PropTypes.string,
      type_id: PropTypes.string,
      type_name: PropTypes.string,
      type_description: PropTypes.string,
      class_name: PropTypes.string,
      domain_id: PropTypes.string,
      domain_name: PropTypes.string,
      generation: PropTypes.number.isRequired,
      process_id: PropTypes.string,
      process_name: PropTypes.string,
      process_description: PropTypes.string,
      process_type_name: PropTypes.string,
      process_is_manual_validation: PropTypes.bool,
      dotColor: PropTypes.string,
      dotColorOrigin: PropTypes.string,
      dotBorderColor: PropTypes.string,
      dotBorderColorOrigin: PropTypes.string,
      reference_date: PropTypes.string,
      origin_reference_date: PropTypes.string,
      status_name: PropTypes.string,
      origin_status_name: PropTypes.string,
      status_id: PropTypes.number,
      origin_status_id: PropTypes.number,
    })
  ).isRequired,
};

export default LineageDrawer;
