/* eslint-disable prefer-object-spread */
import { createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../../data/axiosInstance/axiosInstance";
import executor from "../../../data/axiosInstance/executor";
import {
  openAlert,
  startLoading,
  stopLoading,
} from "../DataQualityStatus/DataQualitySlice";
import { v4 as uuidv4 } from "uuid";
import { connectToWs } from "../ws/WebSocketSlice";

import streamSaver from "streamsaver";

const API_URL = `${process.env.REACT_APP_API_SINISTRI_URL}`;
const USE_API = true;

const initialState = {
  downloads: [],
  currentDownloadRequest: null,
  lastPaginationValues: {},
  downLoadProgress: 0,
  progressBarShow: false,
};

export const DownloadManagerSlice = createSlice({
  name: "downloadManager",
  initialState,
  reducers: {
    //Values
    getDownloads: (state, action) => {
      state.downloads = action.payload;
    },
    getCurrentDownloadRequest: (state, action) => {
      state.currentDownloadRequest = action.payload;
    },
    updateDownloadProgress: (state, action) => {
      state.downLoadProgress = action.payload;
    },
    toggleProgressBar: (state) => {
      state.downLoadProgress = 0;
      state.progressBarShow = !state.progressBarShow;
    },
  },
});

export const {
  //Values
  getDownloads,
  getCurrentDownloadRequest,
  updateDownloadProgress,
  toggleProgressBar,
} = DownloadManagerSlice.actions;

export const fetchDownload =
  (requestId = null, page = null, size = null) =>
  async (dispatch) => {
    console.debug("fetchDownload...");
    dispatch(startLoading());
    dispatch(getDownloads([]));

    let res;
    let err;
    if (USE_API) {
      const API_URI_CALL = `${API_URL}/api/v1/portal/files/status`;
      const fetchData = async () =>
        axiosInstance.get(API_URI_CALL, {
          params: {
            requestId: requestId,
            page: page,
            size: size,
          },
          headers: {
            "Content-Type": "application/json",
            accept: "*/*",
          },
        });
      [res, err] = await executor.executeAsync(fetchData);
      if (err) {
        dispatch(
          openAlert({
            message: "Errore durante il recupero dei download: " + err?.response?.headers?.apierror,
            type: "danger",
          })
        );
      }
    } else {
      //to Load local json without API
    }

    console.debug("fetchDownload res...", res);
    dispatch(getDownloads(res?.data || []));
    return dispatch(stopLoading());
  };

export const requestDownload = (versionId) => async (dispatch) => {
  console.debug("requestDownload...");
  dispatch(startLoading());
  dispatch(connectToWs());
  dispatch(getCurrentDownloadRequest(null));
  dispatch(
    openAlert({ message: "Richiesta del file in corso...", type: "info" })
  );

  console.log(
    "LocalStorage requestIDsJSON ",
    JSON.stringify(localStorage.getItem("requestIds"))
  );

  const requestId = uuidv4();
  console.log("requestDownload requestId  :  ", requestId);

  // Retrieve existing requestIds from local storage
  let requestIds = JSON.parse(localStorage.getItem("requestIds")) || [];

  // Add the new requestId to the array
  requestIds.push({ requestId: requestId, versionId: versionId });

  // Save the updated array back to local storage
  localStorage.setItem("requestIds", JSON.stringify(requestIds));

  let res;
  let err;

  if (USE_API) {
    let API_URI_CALL = `${API_URL}/api/v1/portal/singleVersion/payloadExport/${versionId}`;
    const fetchData = async () =>
      axiosInstance.post(
        API_URI_CALL,
        {},
        {
          headers: {
            requestId: requestId,
          },
        }
      );
    [res, err] = await executor.executeAsync(fetchData);
    if (err) {
      dispatch(
        openAlert({
          message: "Errore durante la richiesta di generazione del file: " + err?.response?.headers?.apierror,
          type: "danger",
        })
      );
      return;
    }
  }

  console.debug("requestDownload res...", res);
  dispatch(getCurrentDownloadRequest(res?.data || null));
  return dispatch(stopLoading());
};

export const exportPayloadWithSocket =
  (requestId, entityId) =>
  async (dispatch) => {
  

    const user = JSON.parse(localStorage.getItem("userInfo"));

    // Fetch the API with streaming response
    fetch(`${API_URL}/api/v1/portal/files/download/${requestId}/${entityId}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${user.access_token}`,
      },
    })
      .then(async (response) => {
        // Check if the response is OK (status 200)
        if (!response.ok) {
          console.error("Error response from server : ", response);
          dispatch(openAlert({ message: "Errore durante il download del file", type: "danger" }));
        }

        const filename = response.headers.get("filename");
        // Create a Stream to process response
        const fileStream = streamSaver.createWriteStream(filename);

        // Pipe the response body directly into the file stream
        if (window.WritableStream && response.body.pipeTo) {
          return response.body.pipeTo(fileStream);
        } else {
          // Fallback for browsers that don't support `pipeTo`
          const writer = fileStream.getWriter();
          const reader = response.body.getReader();

          const { done, value } = await reader.read();
          if (done) {
            writer.close();
            return;
          }
          writer.write(value);
          const result_3 = await reader.read();
          return process(result_3);
        }
      })
      .catch((error) => {
        console.error("Error fetch operation : ", error);
        dispatch(openAlert({ message: "Errore durante il download del file", type: "danger" }));
      });
  };

export default DownloadManagerSlice.reducer;
