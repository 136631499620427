import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import LineageDrawer from "../components/LineageDrawer/LineageDrawer";
import {
  fetchDataLineage,
  setFilterDataProducts,
  setFilterIntervallo,
  setFilterDataDa,
  setFilterExtendedStatsDomains,
  setFilterDataA,
} from "../store/app/DataQualityStatus/DataQualitySlice";
import LoaderSpin from "../components/loaderSpin/LoaderSpin";

import moment from "moment";

const DataLineage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { productTypeId } = useParams();
  const searchParams = new URLSearchParams(location.search);

  const dataLineagePayload = useSelector(
    (state) => state.dataQualityReducer.dataLineage
  );

  const isSpinnerVisible = useSelector(
    (state) => state.dataQualityReducer.loading
  );

  const params = {
    menu: {
      defaultButtons: false,
      buttons: [
        {
          label: "Data Dictionary",
          action: (selectedNode) => {
            console.log("Data Dictionary");
            window.open(
              process.env.REACT_APP_ATLAN_PRE +
                selectedNode.type_id +
                process.env.REACT_APP_ATLAN_POST,
              "_blank"
            );
          },
        },
        {
          label: "Quick Search",
          action: (selectedNode) => {
            console.log("Quick Search");
            dispatch(setFilterDataProducts([selectedNode.product_id]));
            dispatch(setFilterExtendedStatsDomains(selectedNode.status_id || ""));

            dispatch(setFilterIntervallo(-2));

            dispatch(
              setFilterDataDa(
                moment(selectedNode.reference_date).format(
                  "YYYY-MM-DD HH:mm:ss"
                ) || ""
              )
            );
            dispatch(
              setFilterDataA(
                moment(selectedNode.reference_date)
                  .add(24, "hours")
                  .format("YYYY-MM-DD HH:mm:ss") || ""
              )
            );


            searchParams.set("dataproducts",  JSON.stringify([selectedNode.type_id]));

            searchParams.set(
              "fromDate",
              moment(selectedNode.reference_date).format("YYYY-MM-DD HH:mm:ss")
            );
            searchParams.set(
              "toDate",
              moment(selectedNode.reference_date)
                .add(24, "hours")
                .format("YYYY-MM-DD HH:mm:ss")
            );
            searchParams.set("interval", -2);


            navigate("/versioni" + "?" + searchParams);
          },
        },
        {
          label: "Data Lineage",
          action: (selectedNode) => {
            console.log("Data Lineage");
            navigate("/versioni/data-lineage/" + selectedNode.type_id);
          },
        },
      ],
    },
    nodeTooltipKeys: [
      { key: "type_name", displayKey: "Type Name" },
      { key: "domain_name", displayKey: "Domain Name" },
      { key: "class_name", displayKey: "Class Name" },
    ],
    lineTooltipKeys: [
      { key: "process_name", displayKey: "Process Name" },
      { key: "process_type_name", displayKey: "Process Type Name" },
      { key: "process_description", displayKey: "Process Description" },
      { key: "is_manual_validation", displayKey: "Is Manual Validation" },
    ],
    primaryKey: "type_id",
  };

  useEffect(() => {
    dispatch(fetchDataLineage(productTypeId));
  }, [navigate]);

  return (
    <>
      <LoaderSpin isVisible={isSpinnerVisible} />
      <LineageDrawer data={dataLineagePayload} params={params} />
    </>
  );
};

export default DataLineage;
